.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
}
html body {
  overflow-x: hidden !important;
}

/* html body {
  overflow-y: scroll !important;
} */
body > .modal-backdrop.fade.show {
  display: none;
}

.active-modal {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.219);
}

.loginIcon {
  font-size: 4em;
  color: #fcb503 !important;
}

.dropdown-menu-dark {
  background-color: black !important;
}

.nav-tabs {
  border-bottom: 0 !important;
}

#progressbar #verify:before {
  font-family: FontAwesome;
  content: "\33";
}

.heder-rounded-icon {
  /* border: solid 1px white;
    border-radius: 20px;
    color: white;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center; */

  border: none;
  padding: 0px;
  border-radius: 20px;
  color: white;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 20px;
  background: black;
}

.heder-rounded-icon:hover {
  color: #fcb503;
  border-color: #fcb503;
  text-decoration: none;
}

.single-board-link {
  color: #fcb503;
  border-color: #fcb503;
  text-decoration: none;
}

.single-board-link:hover {
  color: #fcb503;
  border-color: #fcb503;
  text-decoration: none;
}

.general-info-form-wrapper label {
  font-size: 0.8rem;
  font-weight: 400 !important;
}

.main-logo {
  max-height: 57px !important;
  padding: 7px !important;
}

.text-red {
  color: red !important;
}

.show-userMenu {
  display: block;
  right: 0;
  position: absolute;
  top: 45px;
}

@media (min-width: 1024px) {
  .witdrow-right-btns {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: space-between !important;
  }
}

@media (max-width: 767px) {
  .inline-xs {
    display: inline !important;
  }

  .show-userMenu {
    top: 1.8rem;
    right: -4rem;
  }
}
